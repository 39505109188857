/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,body {
    min-width: 360px
}
.swiper.loop-fixing .swiper-wrapper .swiper-slide .icon{
  -webkit-transition: 0s !important;
  -moz-transition: 0s !important;
  -o-transition: 0s !important;
  transition: 0s !important;
}

ion-content {
  --background: linear-gradient(180deg, #21314C 13.75%, #195C68 57.75%, #01DABA 120.74%);
  --offset-top: 1px !important;
}

ion-grid {
  padding-bottom: 50px;
}

ion-button {
  height: 45px;
  font-size: 16px;
  max-width: 240px;
  --border-radius: 12px;
  letter-spacing: 0;
  font-weight: 500;
  --box-shadow: none;
}

.spec-character {
  font-family: 'Noto Sans Mono', monospace;
  font-size: 14px;
  color: var(--ion-color-secondary-contrast);
}

.d-flex {
  display: flex;
}

.spinner-rotate {
  animation-duration: 750ms;
  animation: 1s linear 0s infinite normal none running spinner-rotate;
}

.swiper-wrapper {
    overflow: visible;

    .swiper-slide {
        .icon {
          -webkit-transition: 0.3s ease-out;
          -moz-transition: 0.3s ease-out;
          -o-transition: 0.3s ease-out;
          transition: 0.3s ease-out;
          -webkit-transform: translate(0, 36px);
          -moz-transform: translate(0, 36px);
          -o-transform: translate(0, 36px);
          -ms-transform: translate(0, 36px);
          transform: translate(0, 36px);
        }
        &.swiper-slide-visible {
          .icon {
        -webkit-transform: translate(0, 16px);
        -moz-transform: translate(0, 16px);
        -o-transform: translate(0, 16px);
        -ms-transform: translate(0, 16px);
        transform: translate(0, 16px);
          }
        }
        &.swiper-slide-active {
            .icon {
                animation: pulse-animation 2s infinite;
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            }
        }
        &.swiper-slide-duplicate-active {
            .icon {
              -webkit-transform: translate(0, 54px);
              -moz-transform: translate(0, 54px);
              -o-transform: translate(0, 54px);
              -ms-transform: translate(0, 54px);
              transform: translate(0, 54px);
            }
        }

        &.swiper-slide-visible.swiper-slide-next,
        &.swiper-slide-visible.swiper-slide-duplicate-next,
        &.swiper-slide-visible.swiper-slide-duplicate-prev,
        &.swiper-slide-visible.swiper-slide-prev {
            .icon {
            -webkit-transform: translate(0, 4px);
            -moz-transform: translate(0, 4px);
            -o-transform: translate(0, 4px);
            -ms-transform: translate(0, 4px);
            transform: translate(0, 4px);
        }
    }
    &.swiper-slide-duplicate-next,
    &.swiper-slide-duplicate-prev {
      .icon {
        -webkit-transform: translate(0, 54px);
        -moz-transform: translate(0, 54px);
        -o-transform: translate(0, 54px);
        -ms-transform: translate(0, 54px);
        transform: translate(0, 54px);
      }
    }
}
}

@media (min-width: 616px) {

  ion-button {
    height: 64px;
    font-size: 24px;
    max-width: 345px;
    --border-radius: 15px;
    font-weight: 500;
    --box-shadow: none;
  }

  .spec-character {
    font-size: 16px;
  }
}

@media (min-width: 820px) {
  ion-content, ion-footer {
    zoom: 1.3;
  }
}

.lg-wi-cotainer {
  max-width: 820px;
  margin: 0 auto;
}
@media(min-width: 616px) {
  .card-content-md {
    font-size: 16px;
  }

}

@keyframes pulse-animation {

    0% {
        box-shadow: 0 0 0 0px rgb(255 255 255 / 20%);
    }

    100% {
        box-shadow: 0 0 0 30px rgb(255 255 255 / 0%);
    }
}

@keyframes spinner-rotate {

  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
code-input input[type="tel"] {
  width: 42px !important;
  border-radius: 8px !important;
  width: 100% !important;
  max-width: 100% !important;
  box-shadow: none !important;
  }
  @media (min-width: 616px) {

    code-input input[type="tel"] {
      width: 54px !important;
      border-radius: 11px !important;
      }
  }

  // .hideHeader{
  //   app-header ion-buttons.buttons-first-slot.sc-ion-buttons-md-h.sc-ion-buttons-md-s.md.hydrated {
  //     display: none;
  //   }
  
  //   app-header ion-title.md.title-default.hydrated{
  //     color: transparent !important;
  //   }
  //   .disabled_only_color{
  //     color:#999999 !important;
  //   }
  // }
  